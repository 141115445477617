import axios from "axios";

import { storage } from "@services";
import { STORAGE_KEYS } from "@services/storage";
import { ONE_DAY_TIMESTAMP } from "@utils";
import { RequestStatus, SubmitStatus } from "@declaration/types";

type MessageData = {
  name: string;
  email: string;
  message: string;
};

async function canSendMessage() {
  const lastMessage = await storage.getItem(STORAGE_KEYS.LAST_MESSAGE);
  if (!lastMessage) {
    return true;
  }

  const sentDate = new Date(Number(lastMessage) + ONE_DAY_TIMESTAMP * 10);
  const todayDate = new Date();
  if (sentDate < todayDate) {
    return true;
  }

  return false;
}

async function publishMessage({
  name,
  email,
  message
}: MessageData): Promise<SubmitStatus> {
  try {
    await axios.post(
      "https://us-central1-neh-mi.cloudfunctions.net/api/message",
      { name, email, message, subject: "kenailabs - contact form" },
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          auth: "auth-token",
          "Access-Control-Allow-Origin": "*"
        }
      }
    );

    await storage.storeItem(STORAGE_KEYS.LAST_MESSAGE, String(Date.now()));

    return {
      status: RequestStatus.success,
      message: "kenailabs.contact.message_sent"
    };
  } catch (error) {
    return {
      status: RequestStatus.error,
      message: "kenailabs.error.cant_send"
    };
  }
}

export { canSendMessage, publishMessage };
