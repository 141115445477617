import * as React from "react";
import { View } from "react-native";

import NavItem from "@components/NavItem/NavItem";

import styles from "./Nav.styles";

interface NavProps {
  scroll: (index: number) => void;
}

function Nav({ scroll }: NavProps) {
  return (
    <View style={styles.wrapper}>
      <NavItem label="home" icon="home-outline" onPress={() => scroll(0)} />
      <NavItem label="contact" icon="mail-outline" onPress={() => scroll(1)} />
    </View>
  );
}

export default Nav;
