import * as React from "react";
import { ScrollView, TouchableOpacity, View, Linking } from "react-native";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { useTranslation } from "react-i18next";

import { RootStackParamList } from "src/navigator/Navigator";
import { Container, DripsyView, Nav, Text } from "@components";
import { DEVICE_HEIGHT } from "@theme";

import styles from "./Policy.styles";

function Link({
  route,
  children
}: {
  route: string;
  children: React.ReactNode;
}) {
  function onOpenLink() {
    Linking.openURL(route);
  }

  return (
    <TouchableOpacity onPress={onOpenLink}>
      <Text style={styles.link} font="bold">
        {children}
      </Text>
    </TouchableOpacity>
  );
}

function Separator() {
  return <View style={{ marginTop: 20 }} />;
}

function Title({ children }: { children: React.ReactNode }) {
  return (
    <Text style={styles.title} font="bold">
      {children}
    </Text>
  );
}

function Policy() {
  const { t } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { params: { name } = {} } =
    useRoute<RouteProp<RootStackParamList, "Policy">>();

  const decodedName = decodeURIComponent(name || "");

  function onScroll() {
    navigate("Home");
  }

  const title = React.useMemo(() => {
    return t("privacy_policy.title", {
      name: decodedName || "Application"
    });
  }, [decodedName]);

  const appName = React.useMemo(() => {
    return t("privacy_policy.name", {
      name: decodedName || "Application"
    });
  }, [decodedName]);

  return (
    <Container style={{ maxHeight: DEVICE_HEIGHT }}>
      <Nav scroll={onScroll} />
      <ScrollView
        style={{ width: "100%" }}
        showsVerticalScrollIndicator={false}>
        <View style={styles.view}>
          <DripsyView
            sx={{ width: ["100%", "80%", "60%"] }}
            style={styles.wrapper}>
            <Text font="bold" style={styles.large}>
              {title}
            </Text>
            <Separator />
            <Text>{appName}</Text>
            <Separator />
            <Text>privacy_policy.01</Text>
            <Separator />
            <Text>privacy_policy.02</Text>
            <Separator />
            <Text>privacy_policy.03</Text>
            <Separator />
            <Title>privacy_policy.04</Title>
            <Separator />
            <Text>privacy_policy.05</Text>
            <Separator />
            <Text>privacy_policy.06</Text>
            <Separator />
            <Link route="https://www.google.com/policies/privacy/">
              Google Play Services
            </Link>
            <Link route="https://firebase.google.com/policies/analytics">
              Firebase Analytics
            </Link>
            <Separator />
            <Title>Log Data</Title>
            <Separator />
            <Text>privacy_policy.07</Text>
            <Separator />
            <Title>Cookies</Title>
            <Separator />
            <Text>privacy_policy.08</Text>
            <Separator />
            <Title>privacy_policy.09</Title>
            <Separator />
            <Text>privacy_policy.10</Text>
            <Separator />
            <Title>privacy_policy.11</Title>
            <Separator />
            <Text>privacy_policy.12</Text>
            <Separator />
            <Title>privacy_policy.13</Title>
            <Separator />
            <Text>privacy_policy.14</Text>
            <Separator />
            <Link route="mailto:contact@kenailabs.com">
              contact@kenailabs.com
            </Link>
            <Separator />
            <Separator />
            <View style={{ flexDirection: "row" }}>
              <Text>privacy_policy.15</Text>
              <Text> </Text>
              <Text>{new Date("06-06-2023").toDateString()}</Text>
            </View>
            <Separator />
          </DripsyView>
        </View>
      </ScrollView>
    </Container>
  );
}

export default Policy;
