import * as React from "react";
import { ScrollView, ScrollViewProps } from "react-native";

import {
  Container,
  Nav,
  ScrollIndicator,
  Tile,
  LogoTile,
  ContactTile
} from "@components";
import { DEVICE_HEIGHT } from "@theme";
import { isWeb } from "@utils";

const scrollViewConfig: Partial<ScrollViewProps> | false = !isWeb() && {
  snapToInterval: DEVICE_HEIGHT,
  decelerationRate: "fast"
};

function Kenailabs() {
  const scrollRef = React.useRef<ScrollView>(null);

  function scrollAnchor(index: number) {
    scrollRef.current?.scrollTo({ y: index * DEVICE_HEIGHT });
  }

  return (
    <Container style={{ maxHeight: DEVICE_HEIGHT }}>
      <Nav scroll={scrollAnchor} />
      <ScrollView
        ref={scrollRef}
        showsVerticalScrollIndicator={false}
        {...scrollViewConfig}>
        <Tile>
          <LogoTile />
          <ScrollIndicator scroll={() => scrollAnchor(1)} />
        </Tile>
        <Tile>
          <ContactTile />
        </Tile>
      </ScrollView>
    </Container>
  );
}

export default Kenailabs;
