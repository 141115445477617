import * as React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Image } from "dripsy";

import { useTheme } from "@hooks";
import { DEVICE_WIDTH, IMAGES } from "@theme";

const CARINA_DIMENSION = [DEVICE_WIDTH - 40, DEVICE_WIDTH / 2 - 40];

interface ContainerProps {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});

function Container({ children, style }: ContainerProps) {
  const { colors, theme } = useTheme();

  const backgroundColor = React.useMemo(
    () => ({ backgroundColor: colors.white }),
    [colors]
  );

  return (
    <View style={[styles.container, backgroundColor, style]}>
      {theme === "dark" && (
        <Image
          source={IMAGES.CARINA}
          sx={{
            width: CARINA_DIMENSION,
            height: CARINA_DIMENSION,
            position: "absolute",
            alignSelf: "center",
            top: [20, 50],
            opacity: 0.7
          }}
        />
      )}
      {children}
    </View>
  );
}

export default Container;
