import * as React from "react";
import { Text } from "@components";
import { ActivityIndicator, Pressable } from "dripsy";
import { useTheme } from "@hooks";
import { StyleProp, ViewStyle } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from "react-native-reanimated";

interface ButtonProps {
  label: string;
  onPress: () => void;
  showLoader?: boolean;
}

function Button({ onPress, label, showLoader }: ButtonProps) {
  const { colors } = useTheme();
  const animation = useSharedValue(1);

  const wrapperStyle = useAnimatedStyle(
    () => ({
      opacity: animation.value,
      backgroundColor: colors.black,
      padding: 8,
      justifyContent: "center",
      alignItems: "center"
    }),
    []
  );

  function pressIn() {
    animation.value = withTiming(0.4, { duration: 100 });
  }

  function pressOut() {
    animation.value = withTiming(1, { duration: 100 });
  }

  return (
    <Pressable
      style={{ marginTop: 24 }}
      onPress={onPress}
      onPressIn={pressIn}
      onPressOut={pressOut}>
      <Animated.View style={wrapperStyle}>
        {showLoader ? <ActivityIndicator /> : <Text button>{label}</Text>}
      </Animated.View>
    </Pressable>
  );
}

export default Button;
