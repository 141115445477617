import * as React from "react";
import { StyleSheet, View } from "react-native";

import Text from "@components/Text/Text";
import { SubmitStatus } from "@declaration/types";

interface StatusNotificationProps {
  status: SubmitStatus;
}

function StatusNotification({ status }: StatusNotificationProps) {
  const wrapperColor = {
    backgroundColor: status.status === "error" ? "#ff9aa2" : "#e1ffcf"
  };

  return (
    <View style={[styles.wrapper, wrapperColor]}>
      <Text style={{ textAlign: "center", color: "#000" }}>
        {status.message}
      </Text>
    </View>
  );
}

export default StatusNotification;

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});
