import { StyleSheet, Dimensions } from "react-native";

export const DEVICE_WIDTH = Dimensions.get("window").width;
export const DEVICE_HEIGHT = Dimensions.get("window").height;

const styles = StyleSheet.create({
  flexOne: {
    flex: 1
  },
  flexRow: {
    flexDirection: "row"
  }
});

export default styles;

export const IMAGES = {
  LOGO: require("../../assets/kenailabs-logo.png"),
  LOGO_WHITE: require("../../assets/kenailabs-logo-white.png"),
  CARINA: require("../../assets/carina.png")
};
