import * as React from "react";
import { View, StyleSheet } from "react-native";

import { DEVICE_HEIGHT } from "@theme";

interface TileProps {
  children: React.ReactNode;
}

const style = StyleSheet.create({
  wrapper: {
    height: DEVICE_HEIGHT,
    justifyContent: "center",
    alignItems: "center"
  }
});

function Tile({ children }: TileProps) {
  return <View style={style.wrapper}>{children}</View>;
}

export default Tile;
