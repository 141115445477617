import * as React from "react";
import { Pressable } from "react-native";

import { Ionicons } from "@expo/vector-icons";
import { useTheme } from "@hooks";
import { MotiView } from "moti";

interface ScrollIndicatorProps {
  scroll: () => void;
}

const ScrollIndicator = ({ scroll }: ScrollIndicatorProps) => {
  const { colors } = useTheme();

  return (
    <MotiView
      from={{ transform: [{ translateY: 0 }] }}
      animate={{ transform: [{ translateY: -10 }] }}
      transition={{ loop: true }}
      style={{ position: "absolute", bottom: 20 }}>
      <Pressable onPress={scroll}>
        <Ionicons name="chevron-down-outline" size={40} color={colors.black} />
      </Pressable>
    </MotiView>
  );
};

export default ScrollIndicator;
