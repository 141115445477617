import * as React from "react";
import { useColorScheme, Platform } from "react-native";

function useTheme() {
  const color = useColorScheme();

  const colors = React.useMemo(
    () => ({
      white: color === "light" ? "#fff" : "#212121",
      bottom_tab: color === "light" ? "#fff" : "#333333",
      white_presist: "#fff",
      black: color === "light" ? "#212121" : "#fff",
      black_presist: "#212121",
      shadow: color === "light" ? "#212121" : "#eee",
      gray: color === "light" ? "#bebebe" : "#fefefe"
    }),
    [color]
  );

  return {
    colors,
    theme: color
  };
}

export default useTheme;
