import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import {
  createNativeStackNavigator,
  NativeStackNavigationProp
} from "@react-navigation/native-stack";

import { Home, Play, Policy } from "@screens";

export type RootStackParamList = {
  Home?: undefined;
  Play?: undefined;
  Policy?: { name: string };
};

export type ScreenNavigationProp =
  NativeStackNavigationProp<RootStackParamList>;

const Stack = createNativeStackNavigator<RootStackParamList>();

const linking = {
  prefixes: [],
  config: {
    screens: {
      Home: "/",
      Play: "/play",
      Policy: "/policy/:name?"
    }
  }
};

const SCREEN_OPTIONS = { headerShown: false };

function Navigator() {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator screenOptions={SCREEN_OPTIONS}>
        <Stack.Screen
          name="Home"
          component={Home}
          options={{ title: "kenailabs.com" }}
        />
        <Stack.Screen
          name="Policy"
          component={Policy}
          options={{ title: "kenailabs.com" }}
        />
        <Stack.Screen
          name="Play"
          component={Play}
          options={{ title: "kenailabs | play" }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default Navigator;
