import * as React from "react";

import { Container, LogoTile, Text, Tile } from "@components";
import { Image, Linking, TouchableOpacity, View } from "react-native";
import styles from "./PlayStore.styles";

interface PlayProps {}

function Play({}: PlayProps) {
  function openPlay() {
    Linking.openURL(
      "https://play.google.com/store/apps/dev?id=9145593572363649355"
    ).catch(e => console.log(e));
  }

  return (
    <Container style={styles.wrapper}>
      <Tile>
        <View>
          <View style={styles.content}>
            <LogoTile />
          </View>
          <TouchableOpacity onPress={openPlay}>
            <Text style={styles.link} font="bold">
              kenailabs.open_link
            </Text>
            <Image
              source={require("../../../assets/play.png")}
              style={{
                width: 110,
                resizeMode: "contain",
                height: 50,
                alignSelf: "center"
              }}
            />
          </TouchableOpacity>
        </View>
      </Tile>
    </Container>
  );
}

export default Play;
