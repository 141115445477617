import { DEVICE_WIDTH } from "@theme";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapper: {
    zIndex: 2,
    position: "absolute",
    flexDirection: "row",
    backgroundColor: "rgba(0,0,0,0.1)",
    width: "100%",
    justifyContent: "flex-end"
  }
});

export default styles;
