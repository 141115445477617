import * as React from "react";
import { Text as DripsyText } from "dripsy";
import { TextProps, StyleSheet, Text as RNText } from "react-native";
import { useLocalization, useTheme } from "@hooks";

interface ExtendedTextProps extends TextProps {
  font?: "regular" | "light" | "black" | "bold";
  button?: boolean;
}

const styles = StyleSheet.create({
  regular: {
    fontFamily: "Lato-Regular"
  },
  light: {
    fontFamily: "Lato-Light"
  },
  black: {
    fontFamily: "Lato-Black"
  },
  bold: {
    fontFamily: "Lato-Bold"
  }
});

function Text({
  font = "regular",
  button,
  children,
  ...props
}: ExtendedTextProps) {
  const { localize } = useLocalization();
  const { colors } = useTheme();

  const fontStyles = React.useMemo(() => {
    switch (font) {
      case "regular":
        return styles.regular;
      case "light":
        return styles.light;
      case "black":
        return styles.black;
      case "bold":
        return styles.bold;
      default:
        return styles.regular;
    }
  }, [font]);

  const colorStyle = React.useMemo(
    () => ({
      color: button ? colors.white : colors.black
    }),
    [button, colors]
  );

  return (
    <RNText {...props} style={[colorStyle, fontStyles, props.style]}>
      {localize(children)}
    </RNText>
  );
}

export default Text;
