import * as React from "react";
import { useTranslation } from "react-i18next";

function useLocalization() {
  const { t, i18n } = useTranslation();
  const { changeLanguage: i18nChangeLanguage, language: i18nLanguage } = i18n;

  const localize = React.useCallback(
    (text: string | React.ReactNode) => {
      if (typeof text === "string") {
        return t(text);
      }

      return text;
    },
    [t, i18nChangeLanguage, i18nLanguage]
  );

  const changeLanguage = React.useCallback(
    (language: "es" | "en") => {
      i18n.changeLanguage(language);
    },
    [i18nChangeLanguage, i18nLanguage]
  );

  return {
    changeLanguage,
    currentLanguage: i18nLanguage,
    localize
  };
}

export default useLocalization;
