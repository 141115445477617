import "react-native-reanimated";
import "react-native-gesture-handler";

import { useFonts } from "expo-font";
import AppLoading from "expo-app-loading";
import { DripsyProvider, makeTheme } from "dripsy";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { Platform } from "react-native";

import i18next from "@localization";
import fonts from "@theme/fonts";

import Navigator from "./src/navigator/Navigator";

if (Platform.OS === "web") {
  // @ts-ignore
  global._frameTimestamp = null;
}

const initI18n = i18next;
const theme = makeTheme({});

export default function App() {
  const [fontsLoaded] = useFonts(fonts);

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <DripsyProvider theme={theme}>
        <Navigator />
      </DripsyProvider>
    </GestureHandlerRootView>
  );
}
