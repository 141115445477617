import * as React from "react";
import { Image } from "react-native";

import { DripsyView, Text } from "@components";
import { IMAGES } from "@theme";

interface LogoTileProps {}

function LogoTile({}: LogoTileProps) {
  return (
    <DripsyView sx={{ flexDirection: ["column", "row", "row"] }}>
      <DripsyView
        sx={{
          alignItems: ["center", undefined, undefined],
          justifyContent: ["center", undefined, undefined]
        }}>
        <DripsyView
          sx={{
            width: [180, 220, 220],
            height: [180, 220, 220]
          }}>
          <Image
            source={IMAGES.LOGO}
            style={{ width: "100%", height: "100%" }}
          />
        </DripsyView>
      </DripsyView>
      <DripsyView
        sx={{ alignItems: ["center", "flex-start"] }}
        style={{ justifyContent: "center", padding: 40 }}>
        <Text font="bold" style={{ fontSize: 45 }}>
          kenailabs.title
        </Text>
        <Text style={{ fontSize: 30 }}>kenailabs.build_for_fun</Text>
        <Text font="light" style={{ fontSize: 16 }}>
          kenailabs.development
        </Text>
      </DripsyView>
    </DripsyView>
  );
}

export default LogoTile;
