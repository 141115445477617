import * as React from "react";
import { Pressable, StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";

import { Text } from "@components";
import { useTheme } from "@hooks";
import { isWeb } from "@utils";

interface NavItemProps {
  label: string;
  icon: React.ComponentProps<typeof Ionicons>["name"];
  onPress: () => void;
}

const web = isWeb();

const styles = StyleSheet.create({
  wrapper: {
    padding: web ? 20 : 16,
    flexDirection: "row",
    alignItems: "center"
  },
  text: { marginLeft: 10 }
});

function NavItem({ onPress, label, icon }: NavItemProps) {
  const { colors } = useTheme();

  return (
    <Pressable style={styles.wrapper} onPress={onPress}>
      <Ionicons name={icon} size={web ? 18 : 22} color={colors.black} />
      {web && <Text style={styles.text}>{label}</Text>}
    </Pressable>
  );
}

export default NavItem;
