import { ImageSourcePropType } from "react-native";
import { SharedValue } from "react-native-reanimated";

export interface ScrollToOptions {
  animated?: boolean;
  offset?: number;
}

export enum RequestStatus {
  loading = "loading",
  error = "error",
  success = "success"
}

export type SubmitStatus = {
  status: RequestStatus;
  message?: string;
};

export interface ParallaxItemProps {
  image?: ImageSourcePropType;
  index: number;
  translationY: SharedValue<number>;
  children: React.ReactNode;
}
