import { StyleSheet } from "react-native";
import { DEVICE_HEIGHT } from "@theme";

const styles = StyleSheet.create({
  wrapper: {
    maxHeight: DEVICE_HEIGHT
  },
  content: { opacity: 0.9, transform: [{ scale: 0.7 }] },
  link: {
    fontSize: 28,
    alignSelf: "center",
    textDecorationLine: "underline"
  }
});

export default styles;
