import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapper: {
    marginTop: 60,
    backgroundColor: "rgba(0,0,0,0.5)",
    padding: 20
  },
  view: {
    width: "100%",
    alignItems: "center"
  },
  link: {
    marginTop: 3,
    fontSize: 16,
    paddingLeft: 30
  },
  title: {
    fontSize: 22,
    marginTop: 10
  },
  large: {
    fontSize: 34
  }
});

export default styles;
