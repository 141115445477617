const fonts = {
  "Lato-Regular": require("../../assets/fonts/Lato-Regular.ttf"),
  "Lato-Black": require("../../assets/fonts/Lato-Black.ttf"),
  "Lato-Bold": require("../../assets/fonts/Lato-Bold.ttf"),
  "Lato-Light": require("../../assets/fonts/Lato-Light.ttf"),

  // Nico & Lili - Fonts
  "GreatVibes-Regular": require("../../assets/fonts/GreatVibes-Regular.ttf"),
  "MrDeHaviland-Regular": require("../../assets/fonts/MrDeHaviland-Regular.ttf"),
  "DancingScript-Regular": require("../../assets/fonts/DancingScript-Regular.ttf"),
  "DancingScript-Bold": require("../../assets/fonts/DancingScript-Bold.ttf"),
  "Quattrocento-Regular": require("../../assets/fonts/Quattrocento-Regular.ttf"),
  "Quattrocento-Bold": require("../../assets/fonts/Quattrocento-Bold.ttf"),
  "Nunito-Regular": require("../../assets/fonts/Nunito-Regular.ttf"),
  "Nunito-Light": require("../../assets/fonts/Nunito-Light.ttf")
};

export type Fonts = typeof fonts;

export default fonts;
