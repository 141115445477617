import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapper: {
    marginTop: 0
  },
  labelWrapper: {
    position: "absolute",
    zIndex: 10,
    elevation: 10
  },
  input: {
    marginTop: 18,
    borderRadius: 3,
    borderColor: "#ccc",
    padding: 6,
    borderWidth: 1
  },
  error: {
    position: "absolute",
    right: 8,
    top: 22
  },
  errorInput: {
    borderWidth: 1,
    borderColor: "#f00"
  },
  errorText: {
    fontSize: 12,
    paddingHorizontal: 4,
    marginTop: 2,
    marginBottom: 2,
    color: "#f00"
  }
});

export default styles;
