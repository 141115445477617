import AsyncStorage from "@react-native-async-storage/async-storage";

export const STORAGE_KEYS = {
  LAST_MESSAGE: "@last_message"
} as const;

type StorageKeys = typeof STORAGE_KEYS[keyof typeof STORAGE_KEYS];

async function storeItem(key: StorageKeys, value: string) {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (e) {
    console.log(e);
  }
}

async function storeData(key: StorageKeys, value: { [key: string]: string }) {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    console.log(e);
  }
}

async function getItem(key: StorageKeys) {
  try {
    const value = await AsyncStorage.getItem(key);
    return value;
  } catch (e) {
    console.log(e);
  }
}

async function getData<T extends {}>(key: StorageKeys) {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? (JSON.parse(jsonValue) as T) : null;
  } catch (e) {
    console.log(e);
  }
}

export { getData, getItem, storeData, storeItem };
