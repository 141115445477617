import * as React from "react";
import { Feather } from "@expo/vector-icons";
import { useDebounce, useTheme } from "@hooks";
import { MotiView, AnimatePresence } from "moti";
import {
  TextInput,
  TextInputProps,
  TouchableOpacity,
  View
} from "react-native";
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from "react-native-reanimated";
import { Text } from "@components";

import styles from "./Input.styles";

export type InputValidationFunction = {
  validator: (value: string) => boolean;
  message: string;
};

interface InputProps extends TextInputProps {
  label: string;
  showClear?: boolean;
  maxLength?: number;
  customValidations?: InputValidationFunction[];
  actionOnDebounce?: (value: string) => void;
}

export type InputHandlers = {
  validate: () => boolean;
  getValue: () => string;
  setValue: (value: string) => void;
};

const Input: React.ForwardRefRenderFunction<InputHandlers, InputProps> = (
  {
    customValidations,
    label,
    maxLength,
    showClear,
    actionOnDebounce,
    ...props
  },
  forwardedRef
) => {
  const animation = useSharedValue(24);
  const [value, setInputValue] = React.useState("");
  const [error, setError] = React.useState<string>();
  const { colors } = useTheme();

  const debouncedInputValue = useDebounce(value, 500);

  const labelStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: animation.value
        },
        {
          translateX: interpolate(animation.value, [0, 24], [-2, 10])
        },
        {
          scale: interpolate(animation.value, [0, 24], [0.8, 1])
        }
      ]
    };
  }, []);

  function onFocus() {
    animation.value = withTiming(0);
  }

  function onBlur() {
    if (value.length !== 0) {
      return;
    }

    animation.value = withTiming(24);
  }

  function validate() {
    if (customValidations) {
      const customValidators = customValidations
        .map(({ validator, message }) => {
          if (!validator(value)) {
            return message;
          }
        })
        .filter(Boolean);

      if (customValidators.length > 0) {
        return customValidators[0];
      }
    }

    return undefined;
  }

  function runValidations() {
    const validation = validate();

    setError(validation);

    return validation == undefined;
  }

  function onChangeText(text: string) {
    if (Boolean(error)) {
      setError(undefined);
    }

    setInputValue(text);
  }

  React.useImperativeHandle(forwardedRef, () => ({
    validate: () => {
      return runValidations();
    },
    getValue: () => {
      return value;
    },
    setValue: (newValue: string) => {
      if (typeof newValue === "string" && newValue.length > 0) {
        animation.value = withTiming(0);
      }

      setInputValue(newValue);
    }
  }));

  React.useEffect(() => {
    if (!actionOnDebounce) {
      return;
    }

    actionOnDebounce(String(debouncedInputValue));
  }, [debouncedInputValue]);

  return (
    <View style={styles.wrapper}>
      <Animated.View style={[styles.labelWrapper, labelStyles]}>
        <Text>{label}</Text>
      </Animated.View>
      <AnimatePresence>
        {Boolean(error) ? (
          <MotiView
            from={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={styles.error}>
            <TouchableOpacity onPress={() => onChangeText("")}>
              <Feather name="alert-circle" size={20} color="red" />
            </TouchableOpacity>
          </MotiView>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {showClear && value.length > 0 && !Boolean(error) ? (
          <MotiView
            from={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={styles.error}>
            <TouchableOpacity onPress={() => setInputValue("")}>
              <Feather name="x" size={20} color="#aaa" />
            </TouchableOpacity>
          </MotiView>
        ) : null}
      </AnimatePresence>
      <TextInput
        value={value}
        onChangeText={onChangeText}
        onFocus={onFocus}
        onBlur={onBlur}
        style={[
          styles.input,
          Boolean(error) && styles.errorInput,
          { color: colors.black }
        ]}
        {...props}
      />
      <Text style={styles.errorText}>{error || " "}</Text>
    </View>
  );
};

export default React.forwardRef(Input);
