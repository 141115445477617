import * as React from "react";

import { InputHandlers } from "@components/Input/Input";
import { canSendMessage, publishMessage } from "@services/messages";
import { RequestStatus, SubmitStatus } from "@declaration/types";

function useSubmitContact() {
  const [statusResponse, setStatusResponse] = React.useState<SubmitStatus>();

  const nameRef = React.useRef<InputHandlers>(null);
  const emailRef = React.useRef<InputHandlers>(null);
  const messageRef = React.useRef<InputHandlers>(null);

  async function submitAction() {
    if (!emailRef.current || !nameRef.current || !messageRef.current) {
      return;
    }

    setStatusResponse({ status: RequestStatus.loading });

    try {
      const isValid = [
        emailRef.current.validate(),
        nameRef.current.validate(),
        messageRef.current.validate()
      ];

      if (isValid.some(i => !i)) {
        setStatusResponse({
          status: RequestStatus.error,
          message: "kenailabs.error.please_review_form"
        });
        return;
      }

      const isAbleToSend = await canSendMessage();
      if (!isAbleToSend) {
        setStatusResponse({
          status: RequestStatus.error,
          message: "kenailabs.error.too_soon"
        });
        return;
      }

      const result = await publishMessage({
        name: nameRef.current.getValue(),
        email: emailRef.current.getValue(),
        message: messageRef.current.getValue()
      });

      setStatusResponse(result);
    } catch (error) {
      setStatusResponse({
        status: RequestStatus.error,
        message: "kenailabs.error.cant_send"
      });
    }
  }

  return {
    statusResponse,
    submitAction,
    nameRef,
    emailRef,
    messageRef
  };
}

export default useSubmitContact;
