import * as React from "react";
import { Ionicons } from "@expo/vector-icons";
import { AnimatePresence, MotiView } from "moti";

import {
  DripsyView,
  Input,
  Text,
  Button,
  StatusNotification
} from "@components";
import { useSubmitContact, useTheme } from "@hooks";
import { validators } from "@utils";
import { StyleSheet } from "react-native";
import { RequestStatus } from "@declaration/types";

const { minLenghtFormat, isRequired, isValidEmail } = validators;

interface ContactTileProps {}

function ContactTile({}: ContactTileProps) {
  const { colors } = useTheme();
  const { statusResponse, emailRef, nameRef, messageRef, submitAction } =
    useSubmitContact();

  return (
    <DripsyView sx={{ width: ["80%", "50%", "40%"] }}>
      <Ionicons
        name="mail"
        size={40}
        color={colors.black}
        style={{ alignSelf: "center", marginBottom: 20 }}
      />
      <MotiView
        from={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: "timing", duration: 2000 }}
        style={styles.notifications}>
        <AnimatePresence>
          {statusResponse?.message && (
            <StatusNotification status={statusResponse} />
          )}
        </AnimatePresence>
      </MotiView>
      <Text font="light" style={{ alignSelf: "center", marginTop: 20 }}>
        kenailabs.contact.title
      </Text>
      <Input
        ref={nameRef}
        label="kenailabs.contact.name"
        customValidations={[isRequired(), minLenghtFormat(6)]}
        showClear
      />
      <Input
        ref={emailRef}
        label="kenailabs.contact.email"
        customValidations={[isRequired(), minLenghtFormat(6), isValidEmail()]}
        showClear
        autoCapitalize="none"
        keyboardType="email-address"
      />
      <Input
        ref={messageRef}
        label="kenailabs.contact.message"
        customValidations={[isRequired(), minLenghtFormat(20)]}
        multiline
        numberOfLines={4}
      />
      <Button
        label="kenailabs.contact.submit"
        showLoader={statusResponse?.status === RequestStatus.loading}
        onPress={submitAction}
      />
    </DripsyView>
  );
}

export default ContactTile;

const styles = StyleSheet.create({
  notifications: {
    alignSelf: "center",
    width: "100%",
    height: 40,
    justifyContent: "center",
    alignItems: "center"
  }
});
